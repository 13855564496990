import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().UseCases])} />);

// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './ecommerce.module.scss';
// import Footer from "../../components/shared/Footer/Footer";
// import {graphql, useStaticQuery} from 'gatsby';
// import {getImagesFluid, viewports} from '../../components/componentsUtils';
// import useBreakpoint from "../../components/hooks/useBreakpoint";
// import CoveredVideo from "../../components/shared/CoveredVideo/CoveredVideo";
// import SlideTypeF from "../../components/shared/ImageSlider/SlideTypeF";
// import Movie from "../../movies/Inspirations/OnlineSales/inspirations-e-commerce.mp4";
// import OnlineSaleIconBlack from "../../images/icons/online_sales_b.svg";
// import GoToButton from "../../components/shared/GoToButton/GoToButton";
// import {getId, getLink, getRoutes} from "../../components/shared/routes";


// const query = graphql`
// {
//   fluid: allFile(filter: {sourceInstanceName: {eq: "images_OnlineSales"}}) {
//     nodes {
//       childImageSharp {
//         fluid(maxWidth: 1090, quality: 70) {
//           ...GatsbyImageSharpFluid,
//           originalName
//         }
//       }
//     }
//   }
// }`;


// const OnlineSalesPage = () => {
//   const { t } = useTranslation();
//   const pageTitle = `${getRoutes().OnlineSales.pageTitle} | ${getRoutes().Inspirations.pageTitle}`;

//   const imagesFluid = getImagesFluid(useStaticQuery(query));
//   const breakpoint = useBreakpoint();

//   const getSlide2ImageWrapperStyles = () => {
//     switch (breakpoint.viewport) {
//       case viewports.tabletViewport:
//         return {};
//       default:
//         return {
//           width: '620px',
//         };
//     }
//   };

//   const getSlide3ImageWrapperStyles = () => {
//     switch (breakpoint.viewport) {
//       case viewports.tabletViewport:
//         return {
//           width: 'calc(100% - 10px)',
//           maxWidth: '500px',
//           marginLeft: 'auto',
//           marginRight: 'auto',
//           marginTop: '0',
//           marginBottom: '0'
//         };
//       default:
//         return {
//           width: '45%',
//           marginLeft: '100px',
//           marginRight: '100px'
//         };
//     }
//   };



//   return (
//     <Layout>
//       <SEO title={pageTitle} description={'Appealing images drive higher conversions'}/>

//       <div className={css.wrapper}>

//         <div className={css.Ecommerce}>
             
//         <div className={css.wrapper}>
//             <div className={css.slide1}>
//             <CoveredVideo
//                 videoSrcURL={Movie}
//                 placeholderImage={'ECommerce_1_placeholder.jpg'}
//                 imagesFluid={imagesFluid}
//                 icon={OnlineSaleIconBlack}
//                 title="Online Sales"
//                 text={<>Impact the conversion growth <br/>by stunning visual content.</>}
//                 buttons={(
//                     <GoToButton
//                         sectionUrl={getLink([getRoutes().OnlineSales, getRoutes().OnlineSales.sections.getStarted])}
//                         text="Get started!"
//                     />
//                 )}
//             />
//             </div>
//             <div className={css.slide2} id={getRoutes().OnlineSales.sections.getStarted}>
//             <SlideTypeF
//                 title="Make your visual content great!"
//                 text1={<p>A perfect photo can encourage more clicks and drive more sales.</p>}
//                 text2={<p>Turn your product photos into the biggest assets of your online store.</p>}
//                 fullImage={'ECommerce_2.jpg'}
//                 fullImageAlt={'optimisation artifacts removing upscaling'}
//                 phoneImage1={'E-commerce_mobile_1.jpg'}
//                 phoneImage1Alt={'optimisation'}
//                 phoneImage2={'E-commerce_mobile_2.jpg'}
//                 phoneImage2Alt={'artifacts removing'}
//                 phoneImage3={'E-commerce_mobile_3.jpg'}
//                 phoneImage3Alt={'upscaling'}
//                 imagesFluid={imagesFluid}
//                 imagePosition={'right'}
//                 fullImageWrapperStyles={getSlide2ImageWrapperStyles()}
//             />
//             </div>
//             <div className={css.slide3}>
//             <SlideTypeF
//                 title={<>Customizable <br/>workflow settings</>}
//                 text1={<><p>Non-professional photos are gaining a professional look.</p><p>Real time workflow processing.</p></>}
//                 fullImage={'ECommerce_3.jpg'}
//                 fullImageAlt={"rotation size modification "}
//                 phoneImage1={'E-commerce_mobile_4.jpg'}
//                 phoneImage1Alt="rotation size modification auto cropping"
//                 phoneImage2={'E-commerce_mobile_5.jpg'}
//                 phoneImage2Alt="auto cropping"
//                 imagesFluid={imagesFluid}
//                 fullImageWrapperStyles={getSlide3ImageWrapperStyles()}
//             />
//             </div>
//         </div>
//         </div>

//         <div className={css.Footer}>
//           <Footer/>
//         </div>

//       </div>
//     </Layout>
//   );
// };

// export default OnlineSalesPage;


